import { Context } from '@nuxt/types';
import { NEXSTAR_ZIP_CODES } from '@/constants';

/**
 * 1. Redirects any path that does not end with a slash to the same path with a slash.
 * The slash addition is for local development consistency; AWS handles it in production.
 * 2. Redirects from "/onboarding/start" to the heat pump domain when the query parameter "product" equals "heatpump".
 * Handlint heat pump redirection here aims to enhance UX by preventing visible intermediate steps.
 */
export default function redirects({ route, redirect, $config }: Context) {
    // Check if accessing "/onboarding/start" or root path
    const isOnboardingStart =
        route.path === '/onboarding/start' ||
        route.path === '/onboarding/start/' ||
        route.path === '/' ||
        route.path === '';

    // TODO: Investigate why local testing with query param sometimes fail
    // Check if query param "product" has "heatpump"
    const hasHeatPumpProductParam = route.query.product === 'heatpump';

    // Check if zip_code is in the NexStar pilot
    const hasNexStarZipCode = route.query.zip_code && NEXSTAR_ZIP_CODES.has(route.query.zip_code.toString());

    if (isOnboardingStart && hasHeatPumpProductParam && !hasNexStarZipCode) {
        return redirect(`${$config.HEAT_PUMPS_DOMAIN}/dashboard`);
    }

    if (isOnboardingStart && !hasHeatPumpProductParam && hasNexStarZipCode) {
        return redirect({ path: `/onboarding/start/ShoppingFor/`, query: route.query });
    }

    if (route.path.length > 1 && !route.path.endsWith('/')) {
        return redirect({ path: `${route.path}/`, replace: true });
    }
    return true;
}
